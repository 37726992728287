import LinkIcon from '@mui/icons-material/Link'
import GrievancesCreate from './Grievances.create'
import GrievancesEdit from './Grievances.edit'
import GrievancesList from './Grievances.list'

const resource = {
  list: GrievancesList,
  create: GrievancesCreate,
  edit: GrievancesEdit,
  icon: LinkIcon,
}

export default resource
