import LinkIcon from '@mui/icons-material/Link'
import MunicipalitiesAppsList from './Municipalities.list'
import MunicipalitiesCreate from './Municipalities.create'
import MunicipalitiesEdit from './Municipalities.edit'

const resource = {
  list: MunicipalitiesAppsList,
  create: MunicipalitiesCreate,
  edit: MunicipalitiesEdit,
  icon: LinkIcon,
}

export default resource
