import { required } from 'ra-core'
import { FC } from 'react'
import { ArrayInput, BooleanInput, NumberInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin'
import { objectionDecisionChoices } from '../Objections/utils/objectionUtils'

const GrievancesForm: FC = () => (
  <SimpleForm>
    <NumberInput source="grievanceNumber" label="data.grievanceNumber" validate={required()} />
    <TextInput source="name" label="data.name" validate={required()} />
    <TextInput source="description" label="data.description" validate={required()} multiline />
    <TextInput source="explanation" label="data.explanation" validate={required()} multiline />

    <ArrayInput source="decision_texts" label="resources.grievances.decision_texts">
      <SimpleFormIterator>
        <SelectInput source="decision" choices={objectionDecisionChoices} label="resources.objections.decision" />
        <BooleanInput label="data.valueInfluence" source="valueInfluence" />
        <TextInput label="data.description" source="description" multiline />
      </SimpleFormIterator>
    </ArrayInput>

  </SimpleForm>
)

export default GrievancesForm
