import { FC } from 'react'
import { Create } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import GrievancesForm from './GrievancesForm'

const GrievancesCreate: FC = () => (
  <Create
    actions={<ActionsToolbar i18n="resources.grievances.create" />}
    title={<HeaderTitle i18n="resources.grievances.create" />}
  >
    <GrievancesForm />
  </Create>
)

export default GrievancesCreate
