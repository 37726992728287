import AssessmentIcon from '@mui/icons-material/Assessment'
import Check from '@mui/icons-material/Check'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HearingIcon from '@mui/icons-material/Hearing'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Grid, styled, Typography } from '@mui/material'
import { FC, ReactNode, useEffect } from 'react'
import { useGetList, useRefresh, useShowContext, useTranslate } from 'react-admin'
import { DownloadFileButton } from '../../components/buttons/DownloadFileButton'
import { xxllncColor } from '../../layout/themes'
import {
  MunicipalityGrievanceOutWithGrievance, ObjectionGrievanceBase,
  ObjectionOut, ObjectionStatus as Status
} from '../../types/apiRequests'
import Grievance from './Grievance'
import LoadingObjection from './LoadingObjection'

let refreshTime = 5000

const ObjectionsForm: FC = () => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const { record } = useShowContext<ObjectionOut>()
  const dataNotReadyYet = record?.status === Status.RECEIVED

  const { data } = useGetList<MunicipalityGrievanceOutWithGrievance>('municipality_grievances', {
    pagination: { page: 1, perPage: 100 },
    filter: { gemeentecode : `${record?.gemeentecode}` },
  })

  useEffect(() => {
    refreshOnReceivedStatus()
  }, [record])

  const refreshOnReceivedStatus = () => {
    setTimeout(() => {
      if (dataNotReadyYet && record?.created && new Date().getTime() - new Date(record.created).getTime() < 5 * 60 * 1000) {
        refreshTime = refreshTime
        refresh()
        refreshOnReceivedStatus()
      }
    }, refreshTime)
  }

  const CheckMarkOrDash: FC<{ active?: boolean }> = ({ active }) =>
    active ? <Check sx={{ fontSize: 16 }} /> : <RemoveIcon sx={{ fontSize: 16 }} />

  const Property = ({ title, icon, value }: { title: string, icon: ReactNode, value?: boolean }) => (
    <Grid container>
      <Grid item xs={10} style={{ display: 'flex', alignContent: 'center'}} >
        <Typography variant="body1">
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: 10 }}>{icon}</span>
            <span>{title}</span>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={2} >
        <CheckMarkOrDash active={value} />
      </Grid>
    </Grid>
  )

  const AddressDetails = ({ title, value }: { title?: string, value: string }) => (
    <Grid container>
      <Grid item xs={12} >
        { title && <Typography variant="body2">
          {title}
        </Typography>}
        <Typography variant="body1">
          {value}
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <StyledGrid container spacing={8} className={classes.root}>
      <Grid item lg={9} md={12}>
        <Box className={classes.comments}>
          <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
            <span>{translate('resources.objections.grieven')}</span>
          </Typography>
          {(!record?.grieven || record.grieven.length === 0) && record ? (
            <LoadingObjection
              objection={record}
              onRetry={refresh}
            />
          ) : (
            record?.grieven?.map((objectionGrievance: ObjectionGrievanceBase, index: number) => {
              const selectedGrievance = data?.find(gr => gr.grievance.id === objectionGrievance.grievance_id)
              return (
                <Grievance
                  key={objectionGrievance.grievance_id}
                  objectionGrievance={objectionGrievance}
                  index={index}
                  selectedGrievance={selectedGrievance}
                />
              )
            })
          )}
        </Box>
      </Grid>

      <Grid item lg={3} md={12}>
        <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
          <span>{translate('data.objectData')}</span>
        </Typography>
        <AddressDetails
          title={translate('data.objectWozNumber')}
          value={record?.object_woz_number || '-'}
        />
        <AddressDetails
          title={translate('data.objecReferenceNumber')}
          value={record?.object_reference_number || '-'}
        />
        <AddressDetails
          title={translate('data.objectAdress')}
          value={`${record?.object_street || '-'} ${record?.object_house_number || ''}, ${record?.object_city || '-'}`}
        />
        <Typography variant="subtitle1" sx={{ marginTop: '20px', marginBottom: '10px' }}>
          <span>{translate('data.subjectData')}</span>
        </Typography>
        <AddressDetails value={record?.subject_name || '-'} />
        <AddressDetails
          value={`${record?.subject_street || '-'} ${record?.subject_house_number || ''}, ${record?.subject_city || '-'}`}
        />

        <Typography variant="subtitle1" sx={{ marginTop: '20px', marginBottom: '10px' }}>
          <span>{translate('data.properties')}</span>
        </Typography>
        <Property
          title={translate('resources.objections.properties.ProForma')}
          icon={<ReceiptLongIcon sx={{ fontSize: 16 }} />}
          value={record?.inboekvariabelen?.ProForma}
        />
        <Property
          title={translate('resources.objections.properties.Ontvankelijk')}
          icon={<CheckCircleOutlineIcon sx={{ fontSize: 16 }} />}
          value={record?.inboekvariabelen?.Ontvankelijk}
        />
        <Property
          title={translate('resources.objections.properties.Horen')}
          icon={<HearingIcon sx={{ fontSize: 16 }} />}
          value={record?.inboekvariabelen?.Horen}
        />
        <Property
          title={translate('resources.objections.properties.Taxatieverslag')}
          icon={<AssessmentIcon sx={{ fontSize: 16 }} />}
          value={record?.inboekvariabelen?.Taxatieverslag}
        />
        <Property
          title={translate('resources.objections.properties.Onkostenvergoeding')}
          icon={<MonetizationOnIcon sx={{ fontSize: 16 }} />}
          value={record?.inboekvariabelen?.Onkostenvergoeding}
        />
        <Property
          title={translate('resources.objections.properties.UitstelBetaling')}
          icon={<PauseCircleOutlineIcon sx={{ fontSize: 16 }} />}
          value={record?.inboekvariabelen?.UitstelBetaling}
        />
        <Box display="flex" flexDirection="column" gap={2} margin="20px">
          <DownloadFileButton type='pdf' />
          <DownloadFileButton type='letter' />
        </Box>
      </Grid>
    </StyledGrid>
  )
}

const PREFIX = 'xxllncTicketsForm'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  info: `${PREFIX}-info`,
  comments: `${PREFIX}-comments`,
  commenRow: `${PREFIX}-commenRow`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.down('md')]: {
      margin: '0 20px',
      width: 'calc(100% - 35px)',
    }
  },
  [`& .${classes.item}`]: {
    paddingLeft: 0,
  },
  [`& .${classes.info}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: `1px solid ${xxllncColor.dark20}`,
    paddingBottom: '24px',
    marginBottom: '30px',
    marginTop: '16px',
    ['& span']: {
      fontSize: '10pt',
      color: '#6E6E74',
      paddingRight: '20px',
      marginRight: '20px',
      ['&:not(:last-child)']: {
        borderRight: `1px solid ${xxllncColor.dark20}`,
      },
    },
  },
  [`& .${classes.comments}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  [`& .${classes.commenRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    padding: '16px',
  },
}))

export default ObjectionsForm