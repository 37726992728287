import BackIcon from '@mui/icons-material/ArrowBack'
import { Box, Step, StepButton, Stepper } from '@mui/material'
import { useState } from 'react'
import { DeleteWithConfirmButton, Edit, ShowButton, SimpleForm } from 'react-admin'
import { ActionsToolbar } from '../../components'
import { DownloadFileButton } from '../../components/buttons/DownloadFileButton'
import { StepperToolbar, steps } from '../../components/toolbars/StepperToolbar'
import LastUpdated from './LastUpdated'
import ObjectionTitle from './ObjectionTitle'
import DecisionEditStep from './steps/decisions'
import GrievenEditStep from './steps/grieven'
import InboekvariabelenEditStep from './steps/InboekVariabelen'
import JudgementEditStep from './steps/judgement'

const ObjectionsEdit: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <InboekvariabelenEditStep />
      case 1:
        return <GrievenEditStep />
      case 2:
        return <JudgementEditStep />
      case 3:
        return <DecisionEditStep />
      default:
        return null
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Edit
      title="Edit Objection"
      redirect={false}
      actions={<ActionsToolbar >
        <LastUpdated>
          <Box sx={{
            alignSelf: 'end',
            flexGrow: 2
          }}>
            <DownloadFileButton
              type='pdf'
              sx={{ fontSize: '0.75rem', padding: '4px 16px' }}
            />
            <DeleteWithConfirmButton
              label='resources.objections.delete'
              sx={{
                fontSize: '0.75rem', padding: '4px 16px', marginLeft: '10px'
              }}
            />
          </Box>
        </LastUpdated>
        <ObjectionTitle />
        <ShowButton
          variant="text"
          label="resources.objections.back"
          alignIcon="left"
          endIcon={<BackIcon sx={{ marginRight: '15px', marginLeft: '-15px' }}/>}
          startIcon={null}
        />
      </ActionsToolbar>}
    >
      <SimpleForm
        toolbar={<StepperToolbar
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          scrollToTop={scrollToTop}
        />}>
        <Stepper
          activeStep={activeStep}
          style={{ width: '100%', margin: '20px 0' }}
          alternativeLabel
          nonLinear
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={() => setActiveStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ width: '100%', padding: '16px' }}>{renderStep()}</Box>
      </SimpleForm>
    </Edit>
  )
}

export default ObjectionsEdit
