import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { useRecordContext, useTranslate } from 'react-admin'
import { ObjectionOut } from '../../types/apiRequests'

const ObjectionTitle: FC = () => {
  const translate = useTranslate()
  const record = useRecordContext<ObjectionOut>()
  const variabelen = record?.inboekvariabelen

  const date = variabelen?.dagtekening ?
    `| ${translate('data.dagtekening')} ${variabelen?.dagtekening}` : ''

  return (
    <Root className={classes.root}>
      <h1 className={classes.title}>{record ? `${record.description}` : ''}</h1>
      <span className={classes.sdubTitle}>{record ?
        `${translate('data.objection_number')} ${record.objection_number} ${date}` : ''}</span>
    </Root>
  )
}

const PREFIX = 'xxllncObjectionTitle'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  sdubTitle: `${PREFIX}-sdubTitle`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (_props, styles) => styles.root,
})(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexGrow: 2,
    flexDirection: 'column',
  },
  [`& .${classes.title}`]: {
    fontSize: '16pt',
    margin: 0
  },
  [`& .${classes.sdubTitle}`]: {
    fontSize: '10pt',
  },
}))


export default ObjectionTitle