import { FC } from 'react'
import { Create, FileField, FileInput, ReferenceInput, required, SelectInput, SimpleForm, useGetList } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { MunicipalityOut } from '../../types/apiRequests'

const ObjectionsCreate: FC = () => {
  const { data: municipalities } = useGetList<MunicipalityOut>('municipality')

  const isSingleMunicipality = municipalities && municipalities.length === 1
  const hasAtLeastOneMunicipality = municipalities && municipalities.length > 0

  console.log('municipalities', municipalities)
  return (
    <Create
      actions={<ActionsToolbar i18n="resources.objections.create" />}
      title={<HeaderTitle i18n="resources.objections.create" />}
      redirect="show"
    >
      <SimpleForm
        defaultValues={ isSingleMunicipality ? { municipalityId: municipalities[0]?.id } : null}
      >
        {!isSingleMunicipality && (
          <ReferenceInput
            source="municipalityId"
            reference="municipality"
          >
            <SelectInput optionText="name" label="resources.objections.municipality"/>
          </ReferenceInput>
        )}
        <FileInput
          source="file"
          label="PDF"
          accept={{ 'application/pdf': ['.pdf'] }}
          validate={required()}
          disabled={!hasAtLeastOneMunicipality}
          helperText={!hasAtLeastOneMunicipality && 'Please create a municipality first'}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}

export default ObjectionsCreate
