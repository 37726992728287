import { FC } from 'react'
import { Edit } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import GrievancesForm from './GrievancesForm'

const GrievancesEdit: FC = () => (
  <Edit
    actions={<ActionsToolbar i18n="resources.grievances.edit" />}
    title={<HeaderTitle i18n="resources.grievances.edit" />}
  >
    <GrievancesForm />
  </Edit>
)

export default GrievancesEdit
