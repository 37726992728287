import EngineeringIcon from '@mui/icons-material/Engineering'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import { Box, Grid } from '@mui/material'
import { useGetIdentity, useGetList, useGetOne, useRedirect } from 'react-admin'
import { ObjectionOut, StatsOut } from '../../types/apiRequests'
import NewObjectionsChart from './NewObjections.linechart'
import ObjectionPerMunicipality from './ObjectionsPerMunicipality.piechart'
import StatBox from './StatBox'

const Dashboard = () => {
  const redirect = useRedirect()
  const { data } = useGetIdentity()
  const { data: objections } = useGetList<ObjectionOut>('objection')
  const { data: statsData } = useGetOne<StatsOut>(
    'stats',
    { id: (data as { id: string })?.id }
  )

  return (
    <Box >
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} zeroMinWidth onClick={() => redirect('list', 'objection')}>
          <StatBox
            title="objections"
            subtitle="inProgress"
            increase="+14%"
            icon={<EngineeringIcon sx={{ color: '#31679B', fontSize:'28px'}}/>}
            amount={statsData?.activeObjections}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} zeroMinWidth onClick={() => redirect('list', 'objection')}>
          <StatBox
            title="objections"
            subtitle="new"
            increase="+14%"
            icon={<FiberNewIcon sx={{ color: '#31679B', fontSize:'30px'}}/>}
            amount={statsData?.newObjections}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} zeroMinWidth onClick={() => redirect('list', 'objection')}>
          {/* <StatBox
            title="revisions"
            subtitle="lastMonth"
            increase="+14%"
            icon={<ChangeCircleIcon sx={{ color: '#31679B', fontSize:'28px'}}/>}
            amount={statsData?.lastMonthRevisions}
          /> */}
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} marginTop='-16px'>
        <Grid item container spacing={2} xs={12} md={8} display='flex' flexDirection='row'>
          <Grid item xs={12} >
            <Box width="100%" height="300px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
              <NewObjectionsChart objections={objections} />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '-16px' }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '-16px' }}>
                  <Box width="100%" height="320px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
                    {/* <RevisionsBar objections={objections} revisions={revisions} /> */}
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '-16px' }}>
                  <Box width="100%" height="320px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
                    <ObjectionPerMunicipality objections={objections} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={12} md={4} display='flex' flexDirection='column'>
          <Grid item sx={{ height: '100%' }}>
            <Box width="100%" height="100%" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
              {/* <RecentRevisionsLogs objections={objections} revisions={revisions} /> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard