import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  CreateButton, Datagrid, List,
  ListProps, TextField
} from 'react-admin'
import { ListActionsToolbar } from '../../components'

const GrievancesList: FC<ListProps> = (props) => (
  <StyledList
    {...props}
    exporter={false}
    actions={
      <ListActionsToolbar i18n="resources.grievances.list">
        <CreateButton variant="contained" />
      </ListActionsToolbar>
    }
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField
        source="grievanceNumber"
        label="data.grievanceNumber"
      />
      <TextField
        source="name"
        label="data.name"
      />
    </Datagrid>
  </StyledList>
)

const PREFIX = 'xxllncGrievancesList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default GrievancesList
