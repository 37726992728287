export const opacity = {
  o80: '80%',
  o60: '60%',
  o40: '40%',
  o20: '20%',
  o10: '10%',
}

export const xxllncColor = {
  base: '#fff',
  dark100: 'rgba(49,103,155,1)',
  dark80: 'rgba(49,103,155,1)',
  dark60: 'rgba(113,113,112,1)',
  dark40: 'rgba(155,155,154,1)',
  dark20: 'rgba(197,197,196,1)',
  dark10: 'rgba(219,219,217,1)',
  opDark80: 'rgba(49,103,155,' + opacity.o80 + ')',
  opDark60: 'rgba(29,29,29,' + opacity.o60 + ')',
  opDark40: 'rgba(29,29,29,' + opacity.o40 + ')',
  opDark20: 'rgba(29,29,29,' + opacity.o20 + ')',
  opDark10: 'rgba(29,29,29,' + opacity.o10 + ')',
  light100: '#F2F2F2',
  light80: 'rgba(252,252,251,1)',
  light60: 'rgba(249,249,248,1)',
  light40: 'rgba(245,245,244,1)',
  light20: 'rgba(242,242,241,1)',
  opLight80: 'rgba(255,255,255,' + opacity.o80 + ')',
  opLight60: 'rgba(255,255,255,' + opacity.o60 + ')',
  opLight40: 'rgba(255,255,255,' + opacity.o40 + ')',
  opLight20: 'rgba(255,255,255,' + opacity.o20 + ')',
  opLight10: 'rgba(255,255,255,' + opacity.o10 + ')',
  // Blue (xxllnc Bezwaren)
  blue100: 'rgba(30,101,186,1)',
  blue80: 'rgba(72,129,196,1)',
  blue60: 'rgba(114,157,207,1)',
  blue40: 'rgba(155,183,216,1)',
  blue20: 'rgba(197,211,227,1)',
  opBlue80: 'rgba(30,101,186,' + opacity.o80 + ')',
  opBlue60: 'rgba(30,101,186,' + opacity.o60 + ')',
  opBlue40: 'rgba(30,101,186,' + opacity.o40 + ')',
  opBlue20: 'rgba(30,101,186,' + opacity.o20 + ')',
  opBlue10: 'rgba(30,101,186,' + opacity.o10 + ')',
  // Lightblue (xxllnc Bezwaren)
  lightblue100: 'rgba(105,162,255,1)',
  lightblue80: 'rgba(132,178,251,1)',
  lightblue60: 'rgba(159,193,248,1)',
  lightblue40: 'rgba(185,208,244,1)',
  lightblue20: 'rgba(212,223,241,1)',
  opLightblue80: 'rgba(105,162,255,' + opacity.o80 + ')',
  opLightblue60: 'rgba(105,162,255,' + opacity.o60 + ')',
  opLightblue40: 'rgba(105,162,255,' + opacity.o40 + ')',
  opLightblue20: 'rgba(105,162,255,' + opacity.o20 + ')',
  // Green (xxllnc Zaken / Success)
  green100: 'rgba(0,115,77,1)',
  green80: 'rgba(48,140,109,1)',
  green60: 'rgba(96,165,141,1)',
  green40: 'rgba(143,189,173,1)',
  green20: 'rgba(191,214,205,1)',
  opGreen80: 'rgba(0,115,77,' + opacity.o80 + ')',
  opGreen60: 'rgba(0,115,77,' + opacity.o60 + ')',
  opGreen40: 'rgba(0,115,77,' + opacity.o40 + ')',
  opGreen20: 'rgba(0,115,77,' + opacity.o20 + ')',
  opGreen10: 'rgba(0,115,77,' + opacity.o10 + ')',
  // Red (xxllnc Warning)
  red100: 'rgba(194,66,66,1)',
  red80: 'rgba(204,101,100,1)',
  red60: 'rgba(213,136,135,1)',
  red40: 'rgba(221,170,169,1)',
  red20: 'rgba(230,204,203,1)',
  opRed80: 'rgba(194,66,66,' + opacity.o80 + ')',
  opRed60: 'rgba(194,66,66,' + opacity.o60 + ')',
  opRed40: 'rgba(194,66,66,' + opacity.o40 + ')',
  opRed20: 'rgba(194,66,66,' + opacity.o20 + ')',
  opRed10: 'rgba(194,66,66,' + opacity.o10 + ')',
  // Coral
  coral100: 'rgba(226,104,104,1)',
  coral80: 'rgba(229,131,130,1)',
  coral60: 'rgba(232,157,157,1)',
  coral40: 'rgba(234,184,183,1)',
  coral20: 'rgba(236,212,211,1)',
  opCoral80: 'rgba(226,104,104,' + opacity.o80 + ')',
  opCoral60: 'rgba(226,104,104,' + opacity.o60 + ')',
  opCoral40: 'rgba(226,104,104,' + opacity.o40 + ')',
  opCoral20: 'rgba(226,104,104,' + opacity.o20 + ')',
  // Orange (xxllnc Koppelen / Error)
  orange100: 'rgba(232,110,68,1)',
  orange80: 'rgba(234,136,101,1)',
  orange60: 'rgba(235,162,136,1)',
  orange40: 'rgba(236,187,169,1)',
  orange20: 'rgba(237,213,204,1)',
  opOrange80: 'rgba(232,110,68,' + opacity.o80 + ')',
  opOrange60: 'rgba(232,110,68,' + opacity.o60 + ')',
  opOrange40: 'rgba(232,110,68,' + opacity.o40 + ')',
  opOrange20: 'rgba(232,110,68,' + opacity.o20 + ')',
  // Yellow (xxllnc Gegevens)
  yellow100: 'rgba(255,176,62,1)',
  yellow80: 'rgba(252,189,97,1)',
  yellow60: 'rgba(249,202,132,1)',
  yellow40: 'rgba(245,213,167,1)',
  yellow20: 'rgba(242,226,202,1)',
  opYellow80: 'rgba(255,176,62,' + opacity.o80 + ')',
  opYellow60: 'rgba(255,176,62,' + opacity.o60 + ')',
  opYellow40: 'rgba(255,176,62,' + opacity.o40 + ')',
  opYellow20: 'rgba(255,176,62,' + opacity.o20 + ')',
  black: '#000',
  alto: '#d6d6d6',
  gallery: '#efefef',
  silverChalice: '#afafaf',
  mineShaft: '#393939',
  codGray: '#1a1a1a',
  greenPea: '#1e6844',
  pomegranate: '#e74e18',
  gamboge: '#e4970c',
  veniceBlue: '#0e5186',
  curiousBlue: '#2597e1',
  viking: '#79b6dd',
  nileBlue: '#1a3455',
  greenSmoke: '#a2b56f',
  beautyBush: '#efcbcb',
  tallPoppy: '#bc2f2a',
  active: '#D1F0D6',
  activate: '#D8E6F3',
}

export const color = {
  white: '#fff',
  black: '#28282C',
  common: {
    inactive: xxllncColor.alto,
    zakenZaaksysteem: xxllncColor.greenPea,
    dataKoppel: xxllncColor.pomegranate,
    dataIntegraties: xxllncColor.gamboge,
    belastingBezwaren: xxllncColor.veniceBlue,
    belastingMarktanalyse: xxllncColor.curiousBlue,
    belastingVoormeldingen: xxllncColor.viking,
    belasting4: xxllncColor.nileBlue,
    onderwijs1: xxllncColor.greenSmoke,
    omgeving1: xxllncColor.beautyBush,
    omgeving2: xxllncColor.tallPoppy,
    account: xxllncColor.silverChalice,
    cloud: xxllncColor.light100,
    login: xxllncColor.mineShaft,
  },
  primary: {
    light: xxllncColor.mineShaft,
    main: xxllncColor.dark100,
    dark: '#23496d',
    text: xxllncColor.black,
    contrastText: xxllncColor.light100,
  },
  secondary: {
    light: xxllncColor.silverChalice,
    main: xxllncColor.mineShaft,
    dark: xxllncColor.codGray,
    contrastText: xxllncColor.light100,
  },
}
