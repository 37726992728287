import BackArrowIcon from '@mui/icons-material/ArrowBack'
import ForwardIcon from '@mui/icons-material/ArrowForward'
import DecisionIcon from '@mui/icons-material/HowToVote'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import SaveIcon from '@mui/icons-material/Save'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import {
  Button, setSubmissionErrors,
  Toolbar,
  useNotify,
  useRecordContext,
  useSaveContext,
  useTranslate
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { ObjectionDecision, ObjectionOut, ObjectionStatus } from '../../types/apiRequests'
import getLetterAdviceText from '../../utils/getLetterAdviceTexts'
import { DownloadFileButton } from '../buttons/DownloadFileButton'

export const steps = ['Registreren', 'Grieven', 'Besluit', 'Uitspraak']

export const StepperToolbar = ({
  activeStep,
  setActiveStep,
  scrollToTop,
  ...props
}: StepperToolbarProps): JSX.Element => {
  const translate = useTranslate()
  const notify = useNotify()
  const form = useFormContext()
  const saveContext = useSaveContext()
  const record = useRecordContext<ObjectionOut>()
  const isLastStep = activeStep === steps.length - 1

  const letterAdviceTexts = getLetterAdviceText(record)

  const status = form.getValues('status') as ObjectionStatus
  const disabled = status !== ObjectionStatus.UNDER_REVIEW

  const handleSubmit = useCallback(
    async (values, isFinalStep = false, reOpen = false) => {
      let errors
      if ((!disabled || reOpen) && saveContext?.save) {

        const getStatus = () => {
          if (!isFinalStep) return null
          if (reOpen) return { status: ObjectionStatus.UNDER_REVIEW }
          const decision = form.getValues('decision') as  ObjectionDecision
          const newStatus = decision === ObjectionDecision.GROUNDED ?
            ObjectionStatus.ACCEPTED : ObjectionStatus.REJECTED
          return { status: newStatus }
        }

        if (reOpen) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          errors = await saveContext.save({ ...record, ...getStatus() }, {})
        } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          errors = await saveContext.save({ ...values, ...getStatus() }, {})
        }
      }
      if (errors != null) {
        setSubmissionErrors(errors, form.setError)
      }
    },
    [form.setError, saveContext, notify, translate]
  )

  const handleSave = async () => {
    await form.handleSubmit((values) => handleSubmit(values))()
  }

  const handleComplete = async () => {
    await form.handleSubmit((values) => handleSubmit(values, true))()
  }

  const handleReOpen = async () => {
    await form.handleSubmit((values) => handleSubmit(values, true, true))()
  }

  const handleSaveAndNext = async () => {
    await handleSave()
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1)
      scrollToTop()
    }
  }

  const handlePrevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
      scrollToTop()
    }
  }

  return (
    <StyledToolbar className={classes.root} {...props}>
      {activeStep > 0 && (
        <Button
          variant="outlined"
          onClick={handlePrevStep}
          color="primary"
          alignIcon="left"
          label={translate('resources.objections.steps.backTo', { step: steps[activeStep - 1] })}
        >
          <BackArrowIcon />
        </Button>
      )}

      <Button
        variant="outlined"
        color="primary"
        onClick={() => { void handleSave() }}
        label={translate('resources.objections.steps.save')}
        disabled={disabled}
      >
        <SaveIcon />
      </Button>

      {isLastStep ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => { void handleComplete() }}
          label={translate('resources.objections.steps.complete')}
          disabled={disabled || !letterAdviceTexts || letterAdviceTexts.length === 0}
        >
          <DecisionIcon />
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => { void handleSaveAndNext() }}
          label={translate(`resources.objections.steps.${disabled ? 'onlyNext' : 'saveAndNext'}`, { step: steps[activeStep + 1] })}
        >
          <ForwardIcon />
        </Button>
      )}
      {disabled && (
        <>
          <DownloadFileButton type='letter' />
          <Button
            variant="contained"
            color="primary"
            onClick={() => { void handleReOpen() }}
            label={translate('resources.objections.steps.reOpen')}
          >
            <RestartAltIcon />
          </Button>
        </>
      )}
    </StyledToolbar>
  )
}

const PREFIX = 'xxllncStepperToolbar'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
  },
}))

interface StepperToolbarProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
  scrollToTop: () => void;
}

StepperToolbar.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  saveLabel: PropTypes.string,
  scrollToTop: PropTypes.func.isRequired,
}
