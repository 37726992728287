import { FC } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { required } from '../../utils/validation'

const CourtsCreate: FC = () => (
  <Create
    actions={<ActionsToolbar i18n="resources.courts.create" />}
    title={<HeaderTitle i18n="resources.courts.create" />}
  >
    <SimpleForm>
      <TextInput source="name" label="data.name" validate={required()} />
      <TextInput source="teamName" label="data.teamName" validate={required()} />
      <TextInput source="mailbox" label="data.mailbox" validate={required()} />
      <TextInput source="postalCode" label="data.postalCode" validate={required()} />
      <TextInput source="city" label="data.city" validate={required()} />
    </SimpleForm>
  </Create>
)

export default CourtsCreate
