import { Box, InputAdornment, Typography } from '@mui/material'
import React from 'react'
import { FormDataConsumer, NumberInput, SelectInput, TextInput, useRecordContext, useTranslate } from 'react-admin'
import { ObjectionStatus } from '../../../types/apiRequests'
import { objectionDecisionChoices } from '../utils/objectionUtils'

const DecisionEditStep: React.FC = () => {
  const translate = useTranslate()

  const record = useRecordContext()
  const disabled = record?.status !== ObjectionStatus.UNDER_REVIEW

  return (
    <>
      <Typography variant="subtitle1">{translate('resources.objections.decision')}</Typography>
      <SelectInput
        source="decision"
        choices={objectionDecisionChoices}
        label="resources.objections.decision"
        disabled={disabled}
      />
      <TextInput
        multiline
        source="decision_motivation"
        label="resources.objections.decisionMotivation"
        helperText="resources.objections.decisionMotivationHelperText"
        disabled={disabled}
        minRows={3}
      />
      <Typography variant="subtitle1">{translate('resources.objections.waarden')}</Typography>
      <Box display="flex" flexDirection="column">
        <NumberInput
          source="established_value"
          label="resources.objections.established_value"
          step={10000}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          disabled={disabled}
          sx={{ maxWidth: 250 }}
        />
        <NumberInput
          source="claimed_value"
          label="resources.objections.claimed_value"
          step={10000}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          disabled={disabled}
          sx={{ maxWidth: 250 }}
        />
        <FormDataConsumer>
          {({ formData }) => {
            const grounded = formData?.decision === 'GROUNDED'
            return grounded && (
              <NumberInput
                source="corrected_value"
                label="resources.objections.corrected_value"
                step={10000}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                disabled={disabled}
                sx={{ maxWidth: 250 }}
                helperText="resources.objections.corrected_valueHelperText"
              />
            )
          }}
        </FormDataConsumer>

      </Box>
    </>
  )
}

export default DecisionEditStep
