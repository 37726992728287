import LinkIcon from '@mui/icons-material/Link'
import CourtsCreate from './Courts.create'
import CourtsEdit from './Courts.edit'
import CourtsList from './Courts.list'

const resource = {
  list: CourtsList,
  create: CourtsCreate,
  edit: CourtsEdit,
  icon: LinkIcon,
}

export default resource
