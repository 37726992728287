import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled'
import InfoIcon from '@mui/icons-material/Info'
import PendingIcon from '@mui/icons-material/Pending'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Tooltip } from '@mui/material'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { ObjectionStatus } from '../../types/apiRequests'

interface StatusIconProps {
  status?: ObjectionStatus
  created?: string
  color?: boolean
}

const getStatusIcon = (color: boolean, isStalling: boolean, status?: ObjectionStatus) => {
  const iconColor = (defaultColor: 'info' | 'warning' | 'primary' | 'success' | 'error' | 'disabled') =>
    color ? defaultColor : 'disabled'

  switch (status) {
    case ObjectionStatus.RECEIVED:
      return isStalling ? <ErrorIcon color={iconColor('error')} /> : <PendingIcon color={iconColor('warning')} />
    case ObjectionStatus.UNDER_REVIEW:
      return <PlayCircleOutlineIcon color={iconColor('info')} />
    case ObjectionStatus.ADDITIONAL_INFORMATION_REQUESTED:
      return <InfoIcon color={iconColor('primary')} />
    case ObjectionStatus.ACCEPTED:
      return <CheckCircleIcon color={iconColor('success')} />
    case ObjectionStatus.REJECTED:
      return <CancelIcon color={iconColor('error')} />
    case ObjectionStatus.REVISION_REQUESTED:
      return <ErrorIcon color={iconColor('warning')} />
    case ObjectionStatus.RESOLVED:
      return <CheckCircleIcon color={iconColor('success')} />
    case ObjectionStatus.CANCELLED:
      return <CancelIcon color="disabled" />
    default:
      return <HourglassDisabledIcon color="disabled" />
  }
}

const getTooltip = (isStalling: boolean, status?: ObjectionStatus): string =>
  isStalling ? 'resources.objections.status.STALLING' : `resources.objections.status.${status}`

const StatusIcon: FC<StatusIconProps> = ({ status, created, color = true }) => {
  const translate = useTranslate()
  const lifeTime = new Date().getTime() - new Date(created || '').getTime()
  const isStalling = status === ObjectionStatus.RECEIVED && lifeTime > 5 * 60 * 1000

  const icon = getStatusIcon(color, isStalling, status)
  const tooltip = getTooltip(isStalling, status)

  return (
    <Tooltip title={translate(tooltip)}>
      {icon}
    </Tooltip>
  )
}

export default StatusIcon
